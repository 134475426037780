import React, { Component, Fragment } from "react";
import Header from "../Header/Header";
import Footer from "../NewFooter/Footer";
import Loader from "../Loader/Loader";

import { checkLogin } from "../../utils/utils";
import constants from "../../config/constants";
import axios from "../../config/axios";
import FreeGame from "../Common/FreeGame/FreeGame";

import "../Common/FreeGame/FreeGame.css";

export default class AllCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      isAdmin: false,
      isLogin: false,
      categoryGames: [],
      games: [
        {
          name: "Action",
          slug: "action",
          image:
            "https://api.c2gamesstudio.com/assets/images/1605542352701_Vax.png",
        },
        {
          name: "Casual",
          slug: "casual",
          image:
            "https://api.c2gamesstudio.com/assets/images/1599671278900_WhatsApp%20Image%202020-09-09%20at%207.01.45%20PM.jpeg",
        },
        {
          name: "Strategy",
          slug: "strategy",
          image:
            "https://api.c2gamesstudio.com/assets/images/1599674323168_4.png",
        },
        {
          name: "Puzzle",
          slug: "puzzle",
          image:
            "https://api.c2gamesstudio.com/assets/images/1599676942830_9.png",
        },
        {
          name: "Board",
          slug: "board",
          image:
            "https://api.c2gamesstudio.com/assets/images/1605621627418_Ludo.png",
        },
      ],
    };
  }

  async componentDidMount() {
    let isAdmin = false;
    let isLogin = false;
    const loginData = checkLogin();

    if (loginData.success === true) {
      isLogin = true;

      if (loginData.data.role === constants.USER_ROLES.ADMIN) {
        isAdmin = true;
      }
    }

    this.setState({
      ...this.state,
      isAdmin,
      isLogin,
      loader: false,
    });
    const category = await axios.getRequest("/api/v1/category/latest");
    console.log(category);
    if (category.success) {
      this.setState({ ...this.state, categoryGames: category.data });
    }
  }

  render() {
    let gamesContent = (
      <p className="my-md-5 my-4 text-center text-white">No games found!</p>
    );
    if (this.state.categoryGames && this.state.categoryGames.length) {
      gamesContent = (
        <Fragment>
          <div className="mb-0 sectionBg">
            <div className="container-fluid">
              <div className="row align-items-center py-3">
                <div className="col-12">
                  <h3 className="homeGamesName text-uppercase mb-0 text-white">
                    All Categories
                  </h3>
                </div>
              </div>
            </div>
            <div className="container-fluid my-4">
              <FreeGame games={this.state.categoryGames} isCategory={true} />
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Loader show={this.state.loader} />
        <Header isAdmin={this.state.isAdmin} isLogin={this.state.isLogin} />
        {gamesContent}
        <Footer></Footer>
      </Fragment>
    );
  }
}

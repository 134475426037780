import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import logo from "../../assets/images/dropdown/logo.png";
import twitterIcon from "../../assets/images/dropdown/twitter.png";
import fbIcon from "../../assets/images/dropdown/fb.png";
import instIcon from "../../assets/images/dropdown/insta.png";
import linkedInIcon from "../../assets/images/dropdown/linkedIn.png";
import mailIcon from "../../assets/images/dropdown/mail.png";

import FooterBar from "../Common/FooterBar/FooterBar";

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <Fragment>
    <div className={classes.footer}>
      <div className={classes["footer-container"]}>
        <div className={classes["logo-container"]}>
          <img src={logo} alt="logo"></img>
        </div>
        <ul className={classes.links + " px-0"}>
          <li>
            <Link to={`/`} className="d-inline-block c-pointer">
              Home
            </Link>
          </li>
          <li>
            <Link to={`/privacy`} className="d-inline-block c-pointer">
              Privacy
            </Link>
          </li>
          <li>
            <Link to={`/categories`} className="d-inline-block c-pointer">
              All categories
            </Link>
          </li>
          <li>
            <Link to={`/about-us`} className="d-inline-block c-pointer">
              About Us
            </Link>
          </li>
          <li>
            <Link to={`/terms`} className="d-inline-block c-pointer">
              Terms
            </Link>
          </li>
          <li>
            <Link to={`/`} className="d-inline-block c-pointer">
              Blog
            </Link>
          </li>
        </ul>

        <div className={classes["social-icons"]}>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="social" src={twitterIcon}></img>
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="social" src={fbIcon}></img>
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="social" src={instIcon}></img>
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="social" src={linkedInIcon}></img>
          </a>
          <a href="https://mail.com" target="_blank" rel="noopener noreferrer">
            <img alt="social" src={mailIcon}></img>
          </a>
        </div>
      </div>

      <div className={classes["footer-text"]}>
        <p>Terms and Conditions | Privacy Policy</p>
        <p>
          Copyright{" "}
          {`${new Date().getFullYear()}-${new Date().getFullYear() + 1} `}
          Kizzy Games. All right reserved
        </p>
      </div>
    </div>
    <FooterBar />
    </Fragment>
  );
};

export default Footer;

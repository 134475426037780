import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

import "./Carousel.css";

const styles = {
  background: "#afcfac",
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)",
  borderRadius: "20px",
  color: " #ffffff",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "11.33px",
  lineHeight: "13px",
  padding: "0.7rem",
};

const MainCarousel = (props) => {
  return (
    <Carousel
      className="mainCarousel"
      showArrows={false}
      autoFocus={true}
      autoPlay={true}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
    >
      {props.carousels.map((item) => {
        return (
          <div key={item.slug} className="p-3 mx-3 mt-3 wrapper wrapper1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-left">
                <h3 className="mb-2 carouselTitle">{item.name}</h3>
                <p className="mb-3 carouselDesc">{item.desc}</p>
                {/* <button className="btn carouselBtn">PLAY NOW</button> */}
                <Link to={`/play/${item.slug}`} style={styles}>
                  PLAY NOW
                </Link>
              </div>
              <div>
                <img className="img" alt="image1" src={item.image} />
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default MainCarousel;

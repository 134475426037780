import React from "react";
import DropDown from "../../utils/DropDown";
import Header from "../Header/Header";
import classes from "./AboutUs.module.css";
import controllerImage from "../../assets/images/dropdown/controller.png";
import Footer from "../NewFooter/Footer";
import actionLogo from "../../assets/icons/catAction.png";
import adventureLogo from "../../assets/icons/catAdventure.png";
import boardLogo from "../../assets/icons/catBoard.png";
import funnyLogo from "../../assets/icons/catFunny.png";
import girlsLogo from "../../assets/icons/catGirls.png";
import puzzleLogo from "../../assets/icons/catPuzzle.png";
import strategyLogo from "../../assets/icons/catStrategy.png";

const AboutUs = () => {
  const dropdownInfo = [
    { title: "Action Games", logo: actionLogo },
    { title: "Adventure Games", logo: adventureLogo },
    { title: "Board Games", logo: boardLogo },
    { title: "Funny Games", logo: funnyLogo },
    { title: "Girls Games", logo: girlsLogo },
    { title: "Puzzle Games", logo: puzzleLogo },
    { title: "Strategy Games", logo: strategyLogo },
  ];
  const jsx = dropdownInfo.map((item, index) => {
    return (
      <DropDown logo={item.logo} title={item.title} key={index}></DropDown>
    );
  });
  return (
    <>
      <Header></Header>
      <div className={classes.container}>
        <p className={classes["about-us"]}>About Us</p>
        <p className={classes["about-us-text"]}>
          Ut culpa sint in aliquip mollit irure nulla velit incididunt velit qui
          Lorem consequat. Nulla ex est nostrud anim commodo est. Ad non
          adipisicing ex est dolor ipsum aliqua minim do minim exercitation
          veniam.
        </p>
        <div className={classes["image-container"]}>
          <img alt="controller" className={`my-4`} src={controllerImage}></img>
        </div>
      </div>
      {jsx}
      <Footer></Footer>
    </>
  );
};

export default AboutUs;

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import heart from "../../../assets/icons/heart-white.svg";
import like from "../../../assets/icons/like-white.svg";
import play from "../../../assets/icons/play.svg";

import styles from "./Game.module.css";

const FreeGame = (props) => {
  if (!props.games.length) {
    return null;
  }

  return (
    <Fragment>
      {props.games.map((game, index) => {
        const colors = `linear-gradient(to right, rgba(${game.bgColor.color1.r}, ${game.bgColor.color1.g}, ${game.bgColor.color1.b}, ${game.bgColor.color1.a}) 2.41%, rgba(${game.bgColor.color2.r}, ${game.bgColor.color2.g}, ${game.bgColor.color2.b}, ${game.bgColor.color2.a}) 96.66%)`;
        const pathname = !props.isCategory
          ? `/play/${game.slug}`
          : `/category/${game.category.slug}`;

        return (
          <Link
            to={{
              pathname: pathname,
              data: {
                gameURL: game.URL || pathname,
              },
            }}
            key={index}
          >
            <div className={styles.space}>
              <div className={styles.container}>
                {!props.isCategory ? (
                  <div className={styles["right-buttons"]}>
                    <img alt="heart" src={heart}></img>
                    <img alt="like" src={like}></img>
                  </div>
                ) : null}

                <div
                  className={styles.background}
                  style={{ background: colors }}
                ></div>
                <img
                  src={game.image}
                  alt="game-logo"
                  className={styles["game-logo"]}
                ></img>
                <p className={styles.title}>
                  {!props.isCategory
                    ? game.name
                    : `${game.category.name} Games`}
                </p>
                <div className={styles.button}>
                  <img alt="play" src={play}></img>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </Fragment>
  );
};

export default FreeGame;

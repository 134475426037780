import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import FreeGame from "../Common/FreeGame/FreeGame";
import Carousel from "../Home/Carousel/Carousel";
import Trending from "../Home/Trending/Trending";
import Footer from "../NewFooter/Footer";

import axios from "../../config/axios";
import { checkLogin } from "../../utils/utils";
import constants from "../../config/constants";

import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      isAdmin: false,
      isLogin: false,
      homeGames: [],
      carousel: [],
      trendingGames: [],
    };
    ReactGA.initialize(constants.analyticId);
  }

  async getHomeGames() {
    const result = await axios.getRequest("/api/v1/game/home");
    if (result.success === true) {
      return result.data;
    } else {
      return [];
    }
  }

  async componentDidMount() {
    setTimeout(() => {
      ReactGA.pageview(window.location.pathname);
    }, 1000);

    let isAdmin = false;
    let isLogin = false;
    const loginData = checkLogin();

    if (loginData.success === true) {
      isLogin = true;

      if (loginData.data.role === constants.USER_ROLES.ADMIN) {
        isAdmin = true;
      }
    }

    const games = await this.getHomeGames();

    this.setState({
      ...this.state,
      homeGames: games.categoryGames,
      trendingGames: games.trendingGames,
      isLogin,
      isAdmin,
      loader: false,
    });

    const carousel = await axios.getRequest("/api/v1/carousel");
    this.setState({
      ...this.state,
      carousel: carousel.data,
    });
  }

  render() {
    let gamesContent = (
      <p className="my-md-5 my-4 text-center">No games found!</p>
    );

    if (this.state.homeGames && this.state.homeGames.length) {
      gamesContent = this.state.homeGames.map((games, i) => {
        let content = (
          <Fragment key={i}>
            <div
              className={`${
                this.state.homeGames.length - 1 === i ? "bottomSpace" : ""
              } sectionBg`}
            >
              <div className="container-fluid my-4">
                <div className="row align-items-center py-3">
                  <div className="col-lg-6 col-9">
                    <h3 className="homeGamesName text-uppercase mb-0">
                      {games.name} Games
                    </h3>
                  </div>
                  <div className="col-lg-6 col-3 text-right">
                    <Link
                      to={`/category/${games.name}`}
                      className="d-inline-block c-pointer moreLink"
                    >
                      View More
                    </Link>
                  </div>
                </div>
                <FreeGame games={games.games} />
              </div>
            </div>
          </Fragment>
        );

        if (games.games && games.games.length === 0) {
          content = null;
        }
        return content;
      });
    }
    return (
      <Fragment>
        <Loader show={this.state.loader} />
        <Header isAdmin={this.state.isAdmin} isLogin={this.state.isLogin} />
        <Carousel carousels={this.state.carousel} />
        <Trending games={this.state.trendingGames} />
        {gamesContent}
        <Footer />
      </Fragment>
    );
  }
}

import React, { Fragment, useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import CustomButton from "../../Common/Button_1";

import axios from "../../../config/axios";

import "./Trending.css";

const Trending = () => {
  const inputRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [trend, setTrend] = useState([]);

  useEffect(() => {
    const getTrends = async () => {
      const res = await axios.getRequest("/api/v1/trending");

      if (res?.success && res?.data?.length) {
        setTrend(res.data[0].trendings);
      }
    };
    getTrends();
  }, []);

  const onClickHandler = () => {
    setShowModal((prev) => !prev);
  };

  const sendTrendings = async (trendingSlugs) => {
    await axios.postRequest("/api/v1/trending", { trendings: trendingSlugs });

    setShowModal(false);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const data = inputRef.current.value.split(",");

    sendTrendings(data);
  };

  return (
    <Fragment>
      <CustomButton
        click={() => onClickHandler()}
        text="Trending Games"
        isDisabled={false}
      />
      {showModal && (
        <div className="trend-backdrop">
          <div className="trend-form">
            <label>Trending Games</label>
            {/* <input
              ref={inputRef}
              type="text"
              defaultValue={trend.join(",")}
            ></input> */}
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="subway-surfers,ninja"
                name="name"
                ref={inputRef}
                //   onChange={(event) => props.onGameFieldChange("name", event)}
                //   value={props.gameDetails.name}
                className="form-control"
                defaultValue={trend.join(",")}
              />
            </Form.Group>
            <button className="trend-btn" onClick={onSubmitHandler}>
              Update
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Trending;

import React from "react";
import { Link } from "react-router-dom";

import "./Trending.css";

import trending from "../../../assets/images/home/trending/trending.svg";
import trendingEye from "../../../assets/images/home/trending/trending-eye.svg";

const Trending = (props) => {
  if (props.games.length === 0) {
    return <></>;
  }
  return (
    <div className="mx-3">
      <div className="d-flex align-items-center mt-4 mb-3">
        <img alt="trending" src={trending} className="mr-1" />
        <h4 className="mb-0 trendingText">Trending</h4>
      </div>

      <div className="d-flex align-items-center justify-content-center pt-3">
        {props.games.map((game) => (
          <Link key={game.slug} to={`/play/${game.slug}`}>
            <div
              key={game.slug}
              className="trendingCard trendingCard1 p-3 position-relative "
            >
              <img
                alt="trending"
                src={game.image}
                className="position-absolute trendingCard__Img1"
              />
              <span className="d-block mt-4 mb-1 trendingCard__Tittle">
                {game.name}
              </span>
              <div className="d-flex align-items-center justify-content-center">
                <img alt="trending" src={trendingEye} className="mr-2" />
                <span className="d-inline-block trendingCard__Number">
                  {game.views}
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Trending;

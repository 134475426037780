import React, { Fragment, useState, useRef } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import axios from "../../config/axios";
import "./Header.css";

import hamburgerLogo from "../../assets/images/hamburger.svg";
import searchLogo from "../../assets/images/search.svg";
import mainHeaderLogo from "../../assets/images/main-logo.svg";
import crossMenuIcon from "../../assets/icons/cross-menu.svg";
import homeIcon from "../../assets/icons/home-icon.svg";
import aboutUsIcon from "../../assets/icons/about-icon.svg";
import privacyIcon from "../../assets/icons/privacy-icon.svg";
import termsIcon from "../../assets/icons/terms.svg";
import adminIcon from "../../assets/icons/admin.svg";
import categoryIcon from "../../assets/icons/categories.svg";

import twitterIcon from "../../assets/images/dropdown/twitter.png";
import fbIcon from "../../assets/images/dropdown/fb.png";
import instIcon from "../../assets/images/dropdown/insta.png";

const Header = (props) => {
  const [showSearch, setShowSearch] = useState(false);
  const inputRef = useRef();

  const history = useHistory();

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const searchedText = inputRef.current.value;

    history.push(`/search?query=${searchedText}`);
  };

  const searchBarHandler = () => {
    setShowSearch((prev) => !prev);
  };

  const openMobileMenu = () => {
    const mobileMenu = document.querySelector("#mobileMenu");
    mobileMenu.classList.toggle("showMobileMenu");
  };

  const logout = async () => {
    const result = await axios.getRequest("/api/v1/auth/logout");
    if (result.success === true) {
      localStorage.clear();
      window.location.assign("/");
    }
  };

  let loginLink = (
    <li>
      <Link className="d-block" to="/login">
        <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
          <img src={adminIcon} className="mx-3" alt="About us"></img>
          <span className="d-inline-block">Login</span>
        </div>
      </Link>
    </li>
  );

  let signupLink = null;
  let profileLink = null;
  let logoutBtn = null;

  if (props.isLogin === true) {
    logoutBtn = (
      <li onClick={logout}>
        <Link className="d-block" to="#">
          <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
            <img src={adminIcon} className="mx-3" alt="About us"></img>
            <span className="d-inline-block">Logout</span>
          </div>
        </Link>
      </li>
    );

    profileLink = null;
    loginLink = null;
    signupLink = null;

    if (props.isAdmin === true) {
      profileLink = (
        <li>
          <Link className="d-block" to="/admin/games">
            <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
              <img src={adminIcon} className="mx-3" alt="About us"></img>
              <span className="d-inline-block">Admin</span>
            </div>
          </Link>
        </li>
      );
    }
  }

  let hamburgerMenu = (
    <div id="mobileMenu" className="position-absolute">
      <div className="d-flex">
        <div className="container-fluid firstChild sectionBg">
          <div className="row">
            <div className="col-12 text-center my-4 d-flex justify-content-between pl-4 pr-3">
              <Link className="d-inline-block" to="/">
                <img src={mainHeaderLogo} className="mainLogo" alt="Home" />
              </Link>
              <img
                src={crossMenuIcon}
                className="crossMenu c-pointer"
                onClick={openMobileMenu}
                alt="Close menu"
              ></img>
            </div>
            <div className="col-12 px-0">
              <ul className="list-unstyled">
                <li>
                  <Link className="d-block" to="/">
                    <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
                      <img
                        src={homeIcon}
                        className="mx-3"
                        alt="Kizzygame"
                      ></img>
                      <span className="d-inline-block">Home</span>
                    </div>
                  </Link>
                </li>
                {profileLink}
                {loginLink}
                {signupLink}
                <li>
                  <Link className="d-block" to="/about-us">
                    <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
                      <img
                        src={aboutUsIcon}
                        className="mx-3"
                        alt="About us"
                      ></img>
                      <span className="d-inline-block">About Us</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="d-block" to="/terms">
                    <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
                      <img src={termsIcon} className="mx-3" alt="Privacy"></img>
                      <span className="d-inline-block">
                        Terms and Conditions
                      </span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="d-block" to="/privacy">
                    <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
                      <img
                        src={privacyIcon}
                        className="mx-3"
                        alt="Privacy"
                      ></img>
                      <span className="d-inline-block">Privacy</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="d-block" to="/categories">
                    <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
                      <img
                        src={categoryIcon}
                        className="mx-3"
                        alt="All Categories"
                      ></img>
                      <span className="d-inline-block">All Categories</span>
                    </div>
                  </Link>
                </li>
                {logoutBtn}
              </ul>
              <ul className="list-unstyled">
                <li>
                  <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mr-3 ml-3">
                    <span className="d-block followUsHeading text-uppercase">
                      Follow Us
                    </span>
                  </div>
                </li>
                <li>
                  <a
                    className="d-block"
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
                      <img src={fbIcon} className="mx-3" alt="Facebook"></img>
                      <span className="d-inline-block">Facebook</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="d-block"
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
                      <img
                        src={twitterIcon}
                        className="mx-3"
                        alt="Twitter"
                      ></img>
                      <span className="d-inline-block">Twitter</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="d-block"
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="menuWrapper mb-2 d-flex align-items-center justify-content-start pr-3 pl-2 py-2 mx-3">
                      <img
                        src={instIcon}
                        className="mx-3"
                        alt="Instagram"
                      ></img>
                      <span className="d-inline-block">Instagram</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="container-fluid secondChild"
          onClick={openMobileMenu}
        ></div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <header className="header fixed-top">
        <div className="container-fluid">
          <div className="row d-flex align-items-center justify-content-between">
            <div>
              <img
                alt="hamburger-logo"
                className="c-pointer"
                src={hamburgerLogo}
                onClick={openMobileMenu}
              />
            </div>
            <div>
              <Link className="d-inline-block" to="/">
                <img alt="main-logo" src={mainHeaderLogo} />
              </Link>
            </div>
            <div onClick={searchBarHandler} className="search-icon">
              <img alt="search-icon" src={searchLogo} />
            </div>
          </div>
        </div>
        {hamburgerMenu}
        {showSearch && (
          <div className="search-bar">
            <form onSubmit={onSubmitHandler}>
              <input
                ref={inputRef}
                className="search-input"
                type="text"
                placeholder="Search your game"
              ></input>
            </form>
          </div>
        )}
      </header>

      <div className="extraSpace"></div>
    </Fragment>
  );
};

export default withRouter(Header);

import React from "react";
import Header from "../Header/Header";
import Footer from "../NewFooter/Footer";
import classes from "./TermsPrivacy.module.css";
import DropDownWithoutIcon from "../../utils/DropDownWithoutIcon/DropDown";

const PrivacyPolicy = () => {
  return (
    <>
      <Header></Header>
      <div className={classes.container}>
        <p className={classes["about-us"]}>General Terms of Use</p>
        <p className={classes["about-us-text"]}>
          By using Kizzygame.com, you are signifying your assent to the Online
          Privacy Policy of Kizzygame.com. If for any reason you don't agree to
          this privacy policy, please refrain from using this website. We
          reserve the right at any time to make changes to our Privacy Policy.
          You will need to check this website from time to time to ensure that
          you are aware of any changes that we have made. If you continue to use
          this site after the changes have been made, this will signify that you
          accept these changes. We want you to have a great experience when you
          visit Kizzygame.com. You may contact us through email to discuss any
          issue you have with us.
        </p>
      </div>
      <DropDownWithoutIcon
        title="Third Party Cookies"
        content="This website may use various analytical programs such as Google Analytics which is a web analysis program through Google Inc., or more commonly called just Google. The analytics program used by Google contains cookies which will be stored on your computer and analyze web usage. The information that is generated by these cookies on your web usage when you are on this website, will be transmitted and then stored on a Google server which is located in the United State when the IP address becomes activated through your interaction with this website. Your IP address can be shortened if you’re a member of the European Union or another state which has ratified the European Economic Area agreement. Only in a very rare exceptional case will your IP address be sent toa server operated by Google whichis located in United States and then it shortened at that location. The webmaster of Kizzygame.com states that Google will use the information to look at your usage of that information. It will then compile reports on the activities of the website and then provide some other services related to the use of the internet and that of an Internet operator. The IP address that is transmitted by the browser you use through the Google analytics program is not associated with other data that is collected by Google. if you wish to prevent the installation of any cookies that your browser collects,please be aware that you may not be able to use all the features found on this website. If you wish to prevent Google from using Google Analytics, you can visit the Google Website and make changes via the Google Analytics Opt-out page."
      ></DropDownWithoutIcon>
      <DropDownWithoutIcon
        title="Other Sites"
        content="This site may link to other social networks or sitesand these networks make collect cookies. These cookies are used by these sites for analytical purposes and will often be tailored to the interest of the user. We recommend that you read the privacy policies of any of these websites. Kizzygame.com is not responsible for any information that is collected by these websites. To learn more about cookies and how other websites collect them, please visit www.allaboutcookies.org. Enable show you how you can disable or deactivate cookie collection through various websites."
      ></DropDownWithoutIcon>
      <DropDownWithoutIcon
        title="Your Privacy is Important to Us"
        content="Kizzygame.com cares about your privacy and your individual data. If you have any questions or concerns about the day that we collect from you or questions about our terms of service and privacy policy, please contact us."
      ></DropDownWithoutIcon>
      <Footer></Footer>
    </>
  );
};
export default PrivacyPolicy;

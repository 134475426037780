import React, { useState } from "react";
import classes from "./DropDown.module.css";
import upIcon from "../assets/images/dropdown/Vector.png";

const DropDown = (props) => {
  const [hideContent, setHideContent] = useState(false);

  const clickHandler = (e) => {
    setHideContent((prev) => !prev);
  };

  return (
    <div className={hideContent ? classes.dropdown : classes["small-dropdown"]}>
      <div className={classes["image-container"]}>
        <img src={props.logo} alt="logo"></img>
      </div>
      <div>
        <div className={classes.category}>
          <p>{props.title}</p>
          <img
            className={`${classes.upIcon} ${
              !hideContent ? classes.rotate : ""
            } c-pointer`}
            src={upIcon}
            onClick={clickHandler}
            alt="up"
          ></img>
        </div>
        {hideContent && (
          <div className={classes.text}>
            <p>
              loremSit nostrud proident mollit et pariatur sunt adipisicing non.
              Lorem magna commodo in esse ea tempor aliqua qui proident ad.
              Culpa dolor commodo non sint officia occaecat. Sint dolor proident
              magna qui.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;

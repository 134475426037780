import React, { useEffect, useRef, useState } from "react";
import "./CarouselModal.css";

const CarouselModal = (props) => {
  const imageFileRef = useRef();

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [desc, setDesc] = useState("");

  const reset = () => {
    setName("");
    setSlug("");
    setDesc("");
  };

  useEffect(() => {
    if (props.editMode) {
      setName(props.editDetails.name);
      setSlug(props.editDetails.slug);
      setDesc(props.editDetails.desc);
    }
  }, [
    props.editDetails.name,
    props.editDetails.slug,
    props.editDetails.desc,
    props.editMode,
  ]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const editMode = props.editMode;
    props.onSubmit(
      name,
      slug,
      desc,
      imageFileRef.current.files[0],
      reset,
      editMode
    );
  };

  if (props.editMode) {
    return (
      <div className="backdrop">
        <form
          onSubmit={onSubmitHandler}
          className="form"
          encType="multipart/form-data"
        >
          <div className="form-element">
            <input
              type="text"
              placeholder="Title"
              onChange={(e) => {
                setName(e.target.value);
              }}
              defaultValue={props.editDetails.name}
            ></input>
          </div>
          <div className="form-element">
            <input
              type="text"
              placeholder="Slug"
              onChange={(e) => {
                setSlug(e.target.value);
              }}
              defaultValue={props.editDetails.slug}
            ></input>
          </div>
          <div className="form-element">
            <input
              type="text"
              placeholder="Description"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              defaultValue={props.editDetails.desc}
            ></input>
          </div>
          <div className="custom-file">
            <input
              type="file"
              placeholder="Image"
              accept="image/png,image/gif,image/jpeg"
              ref={imageFileRef}
            ></input>
          </div>
          <button className="btn" type="submit">
            Update
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="backdrop">
      <form
        onSubmit={onSubmitHandler}
        className="form"
        encType="multipart/form-data"
      >
        <div className="form-element">
          <input
            type="text"
            placeholder="Title"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          ></input>
        </div>
        <div className="form-element">
          <input
            type="text"
            placeholder="Slug"
            onChange={(e) => {
              setSlug(e.target.value);
            }}
            value={slug}
          ></input>
        </div>
        <div className="form-element">
          <input
            type="text"
            placeholder="Description"
            onChange={(e) => {
              setDesc(e.target.value);
            }}
            value={desc}
          ></input>
        </div>
        <div className="custom-file">
          <input
            type="file"
            placeholder="Image"
            accept="image/png,image/gif,image/jpeg"
            ref={imageFileRef}
          ></input>
        </div>
        <button className="btn" type="submit">
          Add
        </button>
      </form>
      {props.carouselModelInfoMsg}
    </div>
  );
};

export default CarouselModal;

import React, { Component, Fragment } from "react";
import Header from "../Header/Header";

import Loader from "../Loader/Loader";
import FreeGame from "../Common/FreeGame/FreeGame";
import Footer from "../NewFooter/Footer";

import axios from "../../config/axios";
import { checkLogin } from "../../utils/utils";
import constants from "../../config/constants";

import "./Search.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      loader: true,
      isAdmin: false,
      isLogin: false,
      games: [],
    };
  }

  async searchGames(query) {
    const result = await axios.getRequest(`/api/v1/game/search?query=${query}`);
    if (result.success === true) {
      return result.games;
    } else {
      return [];
    }
  }

  async componentDidMount() {
    let isAdmin = false;
    let isLogin = false;
    const loginData = checkLogin();

    if (loginData.success === true) {
      isLogin = true;

      if (loginData.data.role === constants.USER_ROLES.ADMIN) {
        isAdmin = true;
      }
    }

    const search = new URLSearchParams(window.location.search);
    const query = search.get("query");

    if (!query || !query.length) {
      return this.setState({
        ...this.state,
        games: [],
        isLogin,
        isAdmin,
        loader: false,
      });
    }

    const games = await this.searchGames(query);

    this.setState({
      ...this.state,
      query,
      games,
      isLogin,
      isAdmin,
      loader: false,
    });
  }

  render() {
    let gamesContent = (
      <p className="my-md-5 my-4 text-center text-white">No games found!</p>
    );

    if (this.state.games.length) {
      gamesContent = (
        <FreeGame
          games={this.state.games}
          category={`Search for '${window.location.s}'`}
        />
      );
    }

    return (
      <Fragment>
        <Loader show={this.state.loader} />
        <Header isAdmin={this.state.isAdmin} isLogin={this.state.isLogin} />
        <div className={`${"bottomSpace"} sectionBg`}>
          <div className="container-fluid my-4">
            <div className="row align-items-center py-3">
              <div className="col-lg-6 col-9">
                <h3 className="homeGamesName text-uppercase mb-0 text-capitalize">
                  Search for{" "}
                  <span className="d-inline-block text-lowercase">
                    '{this.state.query}'
                  </span>
                </h3>
                {gamesContent}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

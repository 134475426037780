import React, { useState } from "react";
import classes from "./DropDown.module.css";
import upIcon from "../../assets/images/dropdown/Vector.png";

const DropDownWithoutIcon = (props) => {
  const [hideContent, setHideContent] = useState(false);

  const clickHandler = (e) => {
    setHideContent((prev) => !prev);
  };
  return (
    <div className={classes.dropdown}>
      <div className={classes.category}>
        <p>{props.title}</p>
        <img
          className={`${classes.upIcon} ${!hideContent ? classes.rotate : ""} upIcon c-pointer`}
          src={upIcon}
          onClick={clickHandler}
          alt="up"
        ></img>
      </div>
      {hideContent && (
        <div className={classes.text}>
          <p>{props.content}</p>
        </div>
      )}
    </div>
  );
};

export default DropDownWithoutIcon;

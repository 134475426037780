import React from "react";
import Header from "../Header/Header";
import Footer from "../NewFooter/Footer";
import classes from "./TermsPrivacy.module.css";
import DropDownWithoutIcon from "../../utils/DropDownWithoutIcon/DropDown";

const PrivacyPolicy = () => {
  return (
    <>
      <Header></Header>
      <div className={classes.container}>
        <p className={classes["about-us"]}>Privacy Policy</p>
        <p className={classes["about-us-text"]}>
          KizzyGame is committed protecting your privacy when you interact with
          our website. We have adopted an online privacy policy that governs how
          we store, collect, and use information we gather about you online.
        </p>
      </div>
      <DropDownWithoutIcon
        title="What Do You Collect?"
        content="We may request and collect various non-private information from you when you use or when you register for an account at Kizzygame.com We collect this information for your privacy and we ask that you avoid sending us any personally identifiable information."
      ></DropDownWithoutIcon>
      <DropDownWithoutIcon
        title="Use of Cookies"
        content="This website uses cookies to improve the use of our website. this cookie is a small file which website will often store on your computer. When cookies are stored on your personal computer system they will provide a convenient and in easy method for our website to personalize your experience through our website. These cookies can allow us to remember the screen name you have, for example or let us know that you have visited our website in the past. The information stored in these cookies can make your next experience on our website more enjoyable. The information stored in a cookie does not contain personally identifiable information. If you want more information about cookies and what they do you can visit allaboutookies.org to learn more about them."
      ></DropDownWithoutIcon>
      <DropDownWithoutIcon
        title="Your IP Address"
        content="For security and safety reasons, Kizzygame.com may collect information about your IP address."
      ></DropDownWithoutIcon>
      <DropDownWithoutIcon
        title="Links to Third Party Sites"
        content="Kizzygame.com cares about your privacy and your individual data. If you have any questions or concerns about the day that we collect from you or questions about our terms of service and privacy policy, please contact us."
      ></DropDownWithoutIcon>
      <DropDownWithoutIcon
        title="Kizzygames.com and Advertising"
        content="We permit the use of advertising on our site by other third-party advertisers. Some of these advertisers may use technology such as cookies or similar technology to measure their campaign effectiveness. they may use this to deliver a better targeted experience to users or to Target future advertising campaigns. Kizzygame.com complies with data guidelines such as the 1998 Data Protection Act to ensure that the Privacy Information from our users is protected. All of our advertisers are instructed not to display the violent, sexual, alcohol, drug, or similar content on our websites. If you find an infinitive advertisement or inappropriate advertisement onGameskite.com, please email us. some third-party websites may collect various types of information about your visit to Kizzygame.com which may include interaction with the content located on Kizzygame.com"
      ></DropDownWithoutIcon>
      <DropDownWithoutIcon
        title="Permanent Cookies"
        content="When you first visit Kizzygame.com, these are the cookies that will be enabled. These will then be stored on your user device and we'll retrieve information to allow for your navigation on the next visit to our website. These cookies do have an expiration date, but they last beyond just your first session. This type of cookie will enable us to give you personalized information when you visit our website or through your various preferences such as your language, and country of origin."
      ></DropDownWithoutIcon>
      <Footer></Footer>
    </>
  );
};
export default PrivacyPolicy;

import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import Header from "../../Header/Header";
import Loader from "../../Loader/Loader";

import axios from "../../../config/axios";
import { checkLogin } from "../../../utils/utils";

import CustomButton from "../../Common/Button_1";
import GameList from "../Game/GameList/GameList";
import CarouselModal from "./CarouselModal/CarouselModal";
import DeleteCarousel from "./DeleteCarousel/DeleteCarousel";

import constants from "../../../config/constants";

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      isAdmin: false,
      isLogin: false,
      carouselList: [],
      carouselDetails: {},
      showModal: false,
      carouselTitle: "",
      carouselEditId: "",
      isDeleteGame: false,
      deleteGameId: "",
      editCarouselDetails: {},
      editMode: false,
      editCarouselId: "",
    };

    ReactGA.initialize(constants.analyticId);
  }

  isLoggedIn() {
    let isAdmin = false;
    let isLogin = false;

    const loginData = checkLogin();
    if (loginData.success === true) {
      isLogin = true;
      if (loginData.data.role === constants.USER_ROLES.ADMIN) {
        isAdmin = true;
        this.setState({ ...this.state, isAdmin, isLogin });
        // this.getAdminData();
      } else {
        window.location.assign("/profile");
      }
    } else {
      window.location.assign("/login");
      return;
    }
  }

  async componentDidMount() {
    setTimeout(() => {
      ReactGA.pageview(window.location.pathname);
    }, 1000);
    const data = await axios.getRequest("/api/v1/carousel");
    this.setState({ ...this.state, carouselList: data.data });
    this.isLoggedIn();
  }

  async sendCarousel(name, slug, desc, image, reset) {
    const formData = new FormData();

    if (name?.length) formData.append("name", name);
    if (slug?.length) formData.append("slug", slug);
    if (desc?.length) formData.append("desc", desc);
    if (image) formData.append("image", image);

    await axios.patchRequest(
      `/api/v1/carousel/${this.state.editCarouselId}`,
      formData
    );

    reset();
  }

  async submitHandler(name, slug, desc, image, reset, editMode) {
    if (editMode) {
      await this.sendCarousel(name, slug, desc, image, reset);
      this.setState({
        ...this.state,
        showModal: false,
        editCarouselDetails: {},
        editCarousel: false,
        editMode: false,
        editCarouselId: "",
      });
      const data = await axios.getRequest("/api/v1/carousel");
      this.setState({ ...this.state, carouselList: data.data });
    } else {
      const sendCarousel = async () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("slug", slug);
        formData.append("desc", desc);
        formData.append("image", image);
        await axios.postRequest("/api/v1/carousel", formData);
        reset();
      };
      await sendCarousel();
      this.setState({ ...this.state, showModal: false });
      const data = await axios.getRequest("/api/v1/carousel");
      this.setState({ ...this.state, carouselList: data.data });
    }
  }

  showModal() {
    this.setState({
      ...this.state,
      showModal: !this.state.showModal,
      carouselDetails: {},
      carouselTitle: "",
      carouselEditId: "",
      isDeleteGame: false,
      deleteGameId: "",
      editCarouselDetails: {},
      editMode: false,
      editCarouselId: "",
    });
  }

  showDeletGameConfirm(id) {
    this.setState({ ...this.state, isDeleteGame: true, deleteGameId: id });
  }

  cancelDeletGameConfirm() {
    this.setState({ ...this.state, isDeleteGame: false, deleteGameId: "" });
  }

  async onDeletCarousel() {
    const result = await axios.deleteRequest(
      `/api/v1/carousel/${this.state.deleteGameId}`,
      null
    );

    if (result.success === true) {
      const games = [...this.state.carouselList];
      const index = games.findIndex(
        (game) => game._id === this.state.deleteGameId
      );
      if (index > -1) {
        games.splice(index, 1);
        this.setState({
          ...this.state,
          carouselList: games,
          isDeleteGame: false,
          deleteGameId: "",
        });
      }
    } else {
      this.cancelDeletGameConfirm();
    }
  }

  async onEditButton(id) {
    const result = await axios.getRequest(`/api/v1/carousel/${id}`);
    if (result.success === true) {
      const editCarouselDetails = {
        _id: result.data._id,
        name: result.data.name,
        slug: result.data.slug,
        desc: result.data.desc,
        image: result.data.image,
      };

      this.setState({
        ...this.state,
        editCarouselDetails,
        showModal: true,
        editMode: true,
        editCarouselId: id,
        carouselDetails: {},
        carouselTitle: "",
        carouselEditId: "",
        isDeleteGame: false,
        deleteGameId: "",
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Loader show={this.state.loader} />
        <Header isAdmin={this.state.isAdmin} isLogin={this.state.isLogin} />
        <div className="container my-lg-5 my-sm-4 my-3 py-sm-4 py-3 px-sm-4 px-3 loginSignupWrapper rounded">
          <div className="row">
            <div className="col-lg-3 col-6 mb-3 mb-lg-5">
              <CustomButton
                click={this.showModal.bind(this)}
                text="Add Carousel"
                isDisabled={false}
              />
            </div>
            <div className="col-lg-3 col-6">
              <CustomButton
                click={() => this.props.history.push("/admin/games")}
                text="Games List"
                isDisabled={false}
              />
            </div>
          </div>
          {this.state.showModal && (
            <CarouselModal
              editMode={this.state.editMode}
              editDetails={this.state.editCarouselDetails}
              onSubmit={this.submitHandler.bind(this)}
            ></CarouselModal>
          )}
          <div className="row mb-lg-4 mb-3">
            <div className="col-12">
              <h3 className="mb-0 text-white">Home Carousel</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <GameList
                games={this.state.carouselList}
                onEditButton={this.onEditButton.bind(this)}
                showDeletGameConfirm={this.showDeletGameConfirm.bind(this)}
              />
            </div>
            <DeleteCarousel
              showConfirm={this.state.isDeleteGame}
              deleteCarousel={this.onDeletCarousel.bind(this)}
              hideConfirm={this.cancelDeletGameConfirm.bind(this)}
            ></DeleteCarousel>
          </div>
        </div>
      </Fragment>
    );
  }
}

import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import validator from "validator";
import { Form, Button } from "react-bootstrap";
import axios from "../../config/axios";
import Header from "../Header/Header";
import Footer from "../NewFooter/Footer";

import { checkLogin } from "../../utils/utils";
import constants from "../../config/constants";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      errMsgs: [],
      loginDetails: {
        email: "",
        password: "",
      },
      disabledLogin: false,
      loader: false,
      isAdmin: false,
      isLogin: false,
    };
    ReactGA.initialize(constants.analyticId);
  }

  onFieldChange(field, event) {
    const loginDetails = { ...this.state.loginDetails };
    loginDetails[field] = event.target.value;
    this.setState({ ...this.state, loginDetails, isError: false });
  }

  validateDetails(details) {
    const errors = [];

    if (!(details.email && details.email.length)) {
      errors.push("Please enter your email");
    } else {
      if (!validator.isEmail(details.email)) {
        errors.push("Please enter valid email address");
      }
    }

    if (!(details.password && details.password.length)) {
      errors.push("Please enter password");
    } else {
      if (details.password.length < 6) {
        errors.push("Password must be at least 6 characters long");
      }
    }

    return errors;
  }

  async onLogin() {
    const errors = this.validateDetails(this.state.loginDetails);
    if (errors.length) {
      this.setState({ ...this.state, isError: true, errMsgs: errors });
      return;
    }

    const result = await axios.postRequest(
      "/api/v1/auth/login",
      this.state.loginDetails
    );

    if (result?.success === true) {
      localStorage.setItem("token", result.data.token);

      if (result.data.user.role === constants.USER_ROLES.ADMIN) {
        window.location.assign("/admin/games");
      } else {
        window.location.assign("/");
      }
    } else {
      this.setState({ ...this.state, isError: true, errMsgs: [result.msg] });
    }
  }

  isLoggedIn() {
    const loginData = checkLogin();
    if (loginData.success === true) {
      if (loginData.data.role === constants.USER_ROLES.ADMIN) {
        window.location.assign("/admin/games");
      } else {
        window.location.assign("/profile");
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      ReactGA.pageview(window.location.pathname);
    }, 1000);
    this.isLoggedIn();
  }

  render() {
    let errorStrip =
      this.state.isError === true ? (
        <div className="row justify-content-center">
          <div className="col-lg-6 col-12">
            <Form.Group>
              <ul className="mb-0 list-unstyled">
                {this.state.errMsgs.map((msg, i) => (
                  <li key={i}>
                    <p className="mb-0 text-white">{msg}</p>
                  </li>
                ))}
              </ul>
            </Form.Group>
          </div>
        </div>
      ) : null;
    return (
      <Fragment>
        <Header isAdmin={this.state.isAdmin} isLogin={this.state.isLogin} />
        <section>
          <div className="container-fluid py-5">
            <div className="row text-center">
              <div className="col-12">
                <h1 className="mb-3 homeGamesName text-white">
                  Welcome, please login into your account
                </h1>
                <Form noValidate>
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                      <Form.Group>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={(event) =>
                            this.onFieldChange("email", event)
                          }
                          value={this.state.loginDetails.email}
                          className="form-control form-control-lg"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                      <Form.Group>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={(event) =>
                            this.onFieldChange("password", event)
                          }
                          value={this.state.loginDetails.password}
                          className="form-control form-control-lg"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {errorStrip}
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                      <Form.Group className="mb-0">
                        <Button
                          disabled={this.state.disabledLogin}
                          className="rounded agencyLoginBtn px-5 py-2 btn bg-white"
                          type="button"
                          onClick={this.onLogin.bind(this)}
                        >
                          Login
                        </Button>
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}
